const severlist = [{
    img: require('../assets/integration/anissue/4.png'),
    size: ['❉ 技能实训标准制定', '❉ 开展职业技能实训', '❉ 共建高水平双师型教师队伍', '❉ 合作开发新形态技能实训资源', '❉ 职业技能认定与鉴定', '❉ 人才供需精准对接服务', '❉ 教科研创新增值服务'],
    title: '院校服务',
},
{
    img: require('../assets/integration/anissue/5.png'),
    size: ['❉ 联合共建技能培训标准', '❉ 合作开展职业技能培训', '❉ 合作开发特色培训资源', '❉ 赋能企业职工技能培训认定与鉴定, 促进员工职业终身发展', '❉ 协助开展职业技能等级认定及补贴 业务申报'],

    title: '企业服务',
}]

const yiwenlist=[{
    img:require('../assets/integration/anissue/9.png'),
    title:'云题库',
    des:"专家与一线师资团队开发题库，满足学生刷题需求。提前摸清题型,提高应考能力。",
},{
    img:require('../assets/integration/anissue/10.png'),
    title:'云模考',
    des:"考生随时在线模拟,系统自测,让考生针对性补齐短板，提升成绩。",
},{
    img:require('../assets/integration/anissue/11.png'),
    title:'云课程',
    des:"一线名师精讲课程，知识考点精准覆盖,提质增效。",
}]
const lightlist=[{
    img:require('../assets/integration/anissue/12.png'),
    size:['❃  多维度数据统计分析,构建职业技能培训决策基础', '❃  大规模算法AI模型应用，自适应智能学习模式', '❃  学习保真支撑,保证学员学习品质'],
    
},{
    img:require('../assets/integration/anissue/13.png'),
    size:['❃  线上+线下混合式培训,数据互通,构建学习闭环', '❃  一校(企)一专区,校企资源共享,课岗证融通', '❃  专为职业技能培训设计,多种培训方式灵活组合'],
}]




const anissuejson = {
    severlist,
    yiwenlist,
    lightlist

}
export default anissuejson;