<template>
    <div class="anissue">
        <img class="boximg" src="../../assets/integration/anissue/1.png" alt="" srcset="">
        <div class="changejobnav">
            <jobnav name="早幼教全职业群职业证书项目服务" />
        </div>

        <div class="headersever">
            <div class="severbox">
                <div class="servetext">一问依托集团早幼教自营产业优势与教培资源，打通全产业链职业资格与技能培训体系，秉承科技赋能教育的集团理念，打造培训资源数字化训考机制，充分结合传统线下培训模式，为广大考证群体提供数字化专业化高效率的培训服务，助力考生就业与职业生态建设行远致稳，为社会主义新时期早幼教事业发展培养高素质复合型技能人才。</div>
                <div class="serveimgbox">
                    <div class="serveimg">
                        <img src="../../assets/integration/anissue/2.png" alt="">
                    </div>
                    <div class="serveimg">
                        <img src="../../assets/integration/anissue/3.png" alt="">
                    </div>
                </div>
            </div>
            <div class="severlist">
                <div class="severone" v-for="(item,index) in severlist" :key="index">
                    <div class="severleft">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="severright">
                        <div class="severrightimg">
                            <div class="righttitle">{{item.title}}</div>
                        </div>
                        <div class="severtext" v-for="(items,indexs) in item.size" :key="indexs">{{items}}</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="togezher">
            <img src="../../assets/integration/anissue/6.png" alt="">
        </div>
        <jobnav name="职业技能提升培训" />
        <div class="togezher">
            <img src="../../assets/integration/anissue/7.png" alt="">
        </div>
        <jobnav name="培训优势" />
        <div class="togezher1">
            <img src="../../assets/integration/anissue/8.png" alt="">
        </div>
        <jobnav name="一问云职培" />
        <div class="yiwenbox">
            <div class="yiwenone" v-for="(item,index) in yiwenlist" :key="index">
                <div class="yiwentitle">{{item.title}}</div>
                <div class="yiwendes">{{item.des}}</div>
                <div class="yiwenimg">
                    <img :src="item.img" alt="">
                </div>

            </div>
        </div>
        <jobnav name="特色亮点" />
        <div class="lightbox">
            <div class="lightone" v-for="(item,index) in lightlist" :key="index">
                <div class="lightimg">
                    <img :src="item.img" alt="">
                </div>
                <div class="lighttext" v-for="(items,indexs) in item.size" :key="indexs">{{items}}</div>

            </div>
        </div>

    </div>
</template>
<script>
import jobnav from "../../components/jobnav.vue";
import anissuejson from "../../store/anissuejson";
export default {
    components: {
        jobnav,
    },
    data() {
        return {
            severlist: [],
            yiwenlist: [],
            lightlist: [],
        };
    },
    created() {
        this.severlist = anissuejson.severlist;
        this.yiwenlist = anissuejson.yiwenlist;
        this.lightlist = anissuejson.lightlist;
    },
};
</script>
<style lang="scss" scoped>
.anissue {
    overflow-y: hidden;
    .boximg {
        width: 100%;
        margin-bottom: 30px;
    }
    .changejobnav {
        /deep/.jobborderimg {
            width: 497px;
        }
        /deep/.jobsmall {
            width: 480px;
        }
    }
    .headersever {
        width: 80vw;
        margin: 30px auto;

        .severbox {
            width: 100%;
            background: rgba(8, 65, 144, 0.06);
            padding: 20px;
            border-radius: 20px;
            margin: 0 auto 20px;
            .servetext {
                font-size: 18px;
                color: #084190;
                text-indent: 2em;
                margin-bottom: 30px;
            }
            .serveimgbox {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .serveimg {
                    width: 49%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .severlist {
            width: calc(100% + 40px);
            display: flex;
            justify-content: space-between;
            .severone {
                width: 49%;
                height: 244px;
                background: linear-gradient(to right, #084190 0%, #be413f 100%);
                border: 1px solid #f3f3f3;
                display: flex;
                padding-bottom: 4px;
                .severleft {
                    width: 320px;
                    height: 244px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .severright {
                    width: calc(100% - 360px);
                    background: #fff;
                    padding-right: 20px;
                    padding-left: 20px;
                    overflow-y: auto;
                    .severrightimg {
                        width: 170px;
                        background-image: url("../../assets/integration/anissue/icon.png");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        padding: 16px 0px 20px 30px;
                        z-index: 999;
                        .righttitle {
                            font-size: 18px;
                            color: #ffffff;
                        }
                    }
                    .severtext {
                        font-size: 18px;
                        color: #000000;
                    }
                }
            }
        }
    }
    .togezher {
        width: 66vw;
        margin: 30px auto;
        img {
            width: 100%;
        }
    }
    .togezher1 {
        width: 60vw;
        margin: 30px auto;
        img {
            width: 100%;
        }
    }
    .yiwenbox {
        width: 80vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .yiwenone {
            border: 1px solid #ffe1e2;
            padding: 20px;
            .yiwentitle {
                font-size: 20px;
                font-weight: bold;
                color: #b21e23;
                text-align: center;
            }
            .yiwendes {
                font-size: 20px;
                margin: 20px 0;
            }
            .yiwenimg {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
    .lightbox {
        width: 80vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 20px;
        .lightone {
        border: 1px solid #ffe1e2;
            padding: 20px;
            .lightimg {
                width: 100%;
                margin-bottom: 10px;
                img {
                    width: 100%;
                }
            }
            .lighttext {
                font-size: 20px;
            }
        }
    }
}
</style>