<template>
    <div class="jobsmall">
        <div class="jobborderimg">
       <img src="../assets/job/border.png" alt=""></div>
       <div class="jobone">
          <div class="joboneimg">
             <img src="../assets/job/icon.png" alt="">
          </div>
          <div class="jobonetext">{{name}}</div>
       </div>
    </div>
</template>
<script>
export default {
    props: {
        name: String,

    },
};
</script>
<style lang="scss" scoped>
.jobsmall{
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 377px;
    height: 58px;
    .jobborderimg{
        position: absolute;
        top: 0;
        left: 0;
        width: 377px;
        height: 58px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .jobone{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .joboneimg{
            width: 30px;
            height: 29px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .jobonetext{
            font-size: 20px;
            margin-left: 20px;
font-weight: bold;
color: #BE413F;
        }
    }
}
</style>